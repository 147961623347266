import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import kebabCase from 'lodash/kebabCase'
import PropTypes from 'prop-types'
import React from 'react'
import Button from '../components/button'
import Layout from '../components/layout'
import SEO from '../components/seo'

const CategoriesPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <SEO title="Colin's Art" />
    <div className="article">
      <h1 className="text-4xl">Art Portfolio</h1>
      <div className="max-w-md">
        <p>
          I enjoy using a variety of media when responding to the exciting effects of light and
          atmosphere.The paintings if not carried out on site, are developed in my studio from
          studies and reference photos. Particular circumstances create strong memories which are
          incorporated in the images.My drawings describe a narrative developed from particular
          incidents or events.
        </p>
      </div>
      <ul className="list-reset">
        {group.map(category => (
          <li key={category.fieldValue}>
            <h2>{category.fieldValue}</h2>
            <ul className="flex flex-wrap -m-4 list-reset">
              {category.edges.map(item => {
                const { slug } = item.node.fields
                const { title } = item.node.frontmatter
                const { img } = item.node.frontmatter

                return (
                  <li className="p-4 w-full md:w-1/2" key={title}>
                    <Link to={slug}>
                      <Img sizes={img.childImageSharp.sizes} alt={title} />
                    </Link>
                  </li>
                )
              })}
            </ul>
            <div className="flex justify-center">
              <Button href={`/art/${kebabCase(category.fieldValue)}/`}>
                All {category.fieldValue}
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </Layout>
)

CategoriesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default CategoriesPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000, sort: { fields: [frontmatter___order], order: ASC }) {
      group(field: frontmatter___categories, limit: 4) {
        fieldValue
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              img {
                childImageSharp {
                  sizes(
                    maxWidth: 660
                    maxHeight: 660
                    background: "rgba(255,255,255,1)"
                    fit: CONTAIN
                  ) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
